.main-info-list-league-tp {
    width: 100%;
    display: flex;
    align-items: center;
}

.total-div-totalPlayers {
    font-size: xxx-large;
    color: white;
    bottom: 0;
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    justify-content: center;
}

.img-coin-totalPlayers {
    height: 38px;
    margin-right: 10px;
}

.balance_coins-totalPlayers {
    line-height: 38px;
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
}

.small-button-white-text-totalPlayer {
    color: #fff;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 18.9px;
    display: flex;
    align-items: center;
    transition: transform 0.3s ease;

    border-radius: 16px;
    background: rgba(217, 217, 217, 0.05);
    backdrop-filter: blur(7px);
    padding: 12px 16px;
    margin-bottom: 68px
}

.small-button-white-text-totalPlayer span {
    margin-right: 5px;
}

.small-button-white-text-totalPlayer:active {
  transform: scale(1.05);
}


.main-table-tp {
    display: flex;
    width: 100%;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 30px;
    border-radius: 24px;
    background: rgba(217, 217, 217, 0.05);
}


.total-info-league-tp {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 10px;
    color: white;
    display: flex;
    align-items: center;
    padding: 8px 16px;
    border-radius: 16px;
    background: rgba(217, 217, 217, 0.05);
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
    transition: transform 0.3s ease;
}

.item__user-avatar-league-tp {
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    height: 47px;
}

.info-text-tp {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 67%;
    height: 48px;
    padding: 5px 0px 3px 60px;
}

.info-type-tp {
    font-size: 12px;
    font-style: normal;
    font-weight: 274;
}

.league-info-num {
    font-size: 20px;
    font-style: normal;
    font-weight: 590;
}



.online-pin {
    margin-right: 3px;
    margin-bottom: -2px;
}

.button-big-yellow-tp {
    color: #232529;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    width: 100%;
    height: 48px;
    padding: 12px 24px;
    border-radius: 16px;
    background: linear-gradient(180deg, #ffc700 0%, #d78100 100%);
    transition: transform 0.3s ease;
}

.button-big-yellow-tp:active {
  transform: scale(1.05);
}