.redirect-wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: "SF Pro", "SF Pro Display", "Helvetica", sans-serif;
    color: #ffffffe8;
    text-align: center;
  }

  .text-container {
    max-width: 280px;
  }

  .redirect-title {
    font-size: 36px;
    font-weight: bold;
    line-height: 50px;
}