@import url("https://fonts.cdnfonts.com/css/sf-pro-display");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  user-select: none;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
}

.left-align {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.width-100 {
  width: 100%;
}

.justify-end {
  justify-content: end;
}

.absolute {
  position: absolute;
}

.pgR-12 {
  padding-right: 12px;
}

.mrR-6 {
  margin-right: 6px;
}

.mrB-12 {
  margin-bottom: 12px;
}

.mrB-16 {
  margin-bottom: 12px !important;
}

.mrB-24 {
  margin-bottom: 24px;
}

.mrB-140 {
  margin-bottom: 140px !important;
}

.row {
  display: flex;
  width: 100%;
}

.left-align {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.middle-title {
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  margin-bottom: 22px;
  text-align: left;
}

body {
  margin: 0;
  height: 100vh;
  display: flex;
  justify-content: center;

  background-position: top;
  font-family: "SF Pro", "SF Pro Display", "Helvetica", sans-serif;
}

.wrapper-earn {
  margin: 0 auto;
  width: 100%;
  max-width: 430px;
  display: flex;
  height: 100vh;
  position: relative;
  padding: 0 15px;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  background: url(/public/assets/img/league/stars.png),
    linear-gradient(
      180deg,
      #05011e 2.94%,
      #7c01c8 28.76%,
      #500573 68.63%,
      #000423 95.19%
    );
}

/* ------------------------------------- */

.top-imgs {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  flex-direction: column;
  align-items: center;
  margin-bottom: 8px;
}

.top-img {
  width: 264px;
}

.diamond-blink1 {
  position: absolute;
  top: 122px;
  /* left: 6px; */
}

.diamond-blink2 {
  position: absolute;
  top: 133px;
  /* left: 75px; */
}

.diamond-blink3 {
  position: absolute;
  top: 107px;
  /* left: 155px; */
}

/* ------------------------------------- */

.big-white-title {
  color: #fff;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 31px;
  margin-bottom: 16px;
  top: 183px;
}

.small-button-white-text {
  color: #fff;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18.9px;
  display: flex;
  align-items: center;
  transition: transform 0.3s ease;

  border-radius: 16px;
  background: rgba(217, 217, 217, 0.05);
  backdrop-filter: blur(7px);
  padding: 12px 16px;
}

.small-button-white-text:active {
  transform: scale(1.1);
}

.small-button-white-text span {
  margin-right: 5px;
}

/* ------------------------------------- */

.invite-button-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  border-radius: 24px;
  background: rgba(217, 217, 217, 0.05);
  padding: 15px;
  margin-bottom: 32px;
}

.invite-button {
  width: 100%;
  height: 69px;
  margin: 0 auto;
  margin-bottom: 10px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 21px 24px 21px 12px;

  border-radius: 16px;
  background: rgba(217, 217, 217, 0.05);
  backdrop-filter: blur(7px);
  transition: transform 0.3s ease;
}

.invite-button-click:active {
  transform: scale(1.03);
}

.simple {
  flex-direction: column;
  justify-content: flex-start;
}

.claim-button {
  width: 100%;
  margin: 0 auto;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 21px 24px 21px 12px;
  border-radius: 16px;
  background: rgba(217, 217, 217, 0.05);
  backdrop-filter: blur(7px);
  transition: transform 0.3s ease;
}

.total-container {
  display: flex;
  align-items: center;
}

.img-coin {
  height: 27px;
  margin-right: 5px;
}

.mini-butttom-icons {
  width: 50px;
}

.one-time-bonus {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 590;
  line-height: 18.9px;
}

.small-text-button {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 274;
  line-height: 18.9px;
}

.two-row {
  display: flex;
  align-items: center;
  gap: 5px;
}

.row-coin {
  padding-bottom: 2px;
}

.column-button {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 67%;
  height: 48px;
  padding: 7px 0px 0px 0px;
  /* border: 2px solid yellow; */
}

/* ------------------------------------- */

.button-big-yellow {
  color: #232529;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;

  width: 100%;
  height: 48px;
  padding: 12px 24px;
  border-radius: 16px;
  background: linear-gradient(180deg, #ffc700 0%, #d78100 100%);
  transition: transform 0.3s ease;
}

.button-big-yellow:active {
  transform: scale(1.01);
}

/* react svg fix */
.fix-mix-blend-mode,
.diamond-path {
  mix-blend-mode: screen;
}

@media screen and (min-width: 431px) {
  /* CSS стили, применяемые при ширине экрана больше 430px */
  .wrapper {
    overflow-x: visible;
  }
}
