.claim__wrappeer {
  width: 100%;
  max-width: 430px;
  display: flex;
  height: 100vh;
  position: relative;
  padding: 0 15px;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  margin: 0 auto;
  position: relative;
}

.claim__title {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  padding-top: 37px;
  margin-bottom: 32px;
}

.claim__form-bg {
  width: 100%;
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 24px;
  background: rgba(217, 217, 217, 0.05);
}

.form__first-row {
  width: 100%;
  display: flex;
  padding: 24px 16px 24px 20px;
  border-radius: 16px;
  background: rgba(217, 217, 217, 0.08);
  backdrop-filter: blur(4.5px);
  margin-bottom: 8px;
  position: relative;
}

.form__first-row-step-2 {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 36px 20px;
  border-radius: 16px;
  background: rgba(217, 217, 217, 0.08);
  backdrop-filter: blur(4.5px);
  margin-bottom: 8px;
  position: relative;
}

.form__column {
  display: flex;
  flex-direction: column;
  width: calc(100% - 44px);
  position: relative;
}

.form__column-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  flex-direction: column;
  gap: 10px;
}

.form__big-text {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 20px;
  max-width: 90%;
}

.form__big-text-step-2 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  max-width: 90%;
}

.form__medium-text {
  font-size: 16px;
  font-style: normal;
  font-weight: 510;
  line-height: 22.909px;
  margin-bottom: 8px;
}

.form__small-text {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  font-style: normal;
  font-weight: 510;
  line-height: 12px;
}

.form__warning {
  display: flex;
  align-items: center;
  color: #f04e09;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  position: absolute;
  top: 58px;
}

.form__circle {
  width: 68px;
  height: 68px;
  border-radius: 50%;
  background-color: #3f0e67;
  border: 3.29px solid #3f0e68;
  position: absolute;
  left: calc(50% - 34px);
  top: -40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.teather-Bg {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: #50af95;
  display: flex;
  justify-content: center;
  align-items: center;
}

input {
  color: white;
  height: 28px;
  border: none;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  background: rgba(217, 217, 217, 0);
  margin-bottom: 20px;
  max-width: 90%;
  font-family: "SF Pro", "SF Pro Display", "Helvetica", sans-serif;
}

input::placeholder {
  color: rgba(0, 0, 0, 0.5);
}

input::placeholder {
  color: white;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
}

input:focus {
  outline: none;
}

.claim__button-big-yellow {
  color: #232529;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  width: 100%;
  height: 48px;
  padding: 16px 24px;
  border-radius: 16px;
  background: linear-gradient(180deg, #ffc700 0%, #d78100 100%);
  transition: transform 0.3s ease;
}

.claim__button-big-yellow:active {
  transform: scale(1.02);
}

/* Step-2 */

.claim__form-bg-wallet {
  width: 100%;
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  border-radius: 24px;
  background: rgba(217, 217, 217, 0.05);
  backdrop-filter: blur(4.5px);
}

.wallet-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 510;
  margin-bottom: 10px;
  line-height: 23px;
}

.wallet-text {
  color: rgba(255, 255, 255, 0.5);
  font-size: 16px;
  font-style: normal;
  font-weight: 510;
  margin-bottom: 10px;
  line-height: 23px;
}

.input-wrapper {
  position: relative;
  width: 100%;
  height: 48px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 20px;
  padding-left: 16px;
  align-items: center;
  border-radius: 16px;
  background: var(--Light-Surface-Elevation, rgba(101, 103, 114, 0.08));
  box-sizing: border-box;

  /* border: 2px solid #EFD006; */
}

input.wallet-input {
  height: 44px;
  width: 85%;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
  margin-bottom: 0;
  box-sizing: border-box;
}

input.wallet-input::placeholder {
  color: rgba(255, 255, 255, 0.4);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}



.wallet-input-icon {
  position: absolute;
  top: calc(50% - 10px);
  left: 90%;
}

.error-message {
  color: #f04e09;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  margin-top: 4px;
}


/* Step-3 */
.top-img-step-3 {
  margin-top: 85px;
}

.top-light {
  margin-bottom: 24px;
}

.claim__button-big-yellow-step-3 {
  color: #232529;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  width: 85%;
  height: 48px;
  padding: 16px 24px;
  border-radius: 16px;
  background: linear-gradient(180deg, #ffc700 0%, #d78100 100%);
  transition: transform 0.3s ease;
  position: fixed;
  bottom: 10%;
}

.claim__button-big-yellow-step-3:active {
  transform: scale(1.02);
}

.very-big-title-step-3 {
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.909px;
  margin-bottom: 16px;
}

.trans-text-step-3 {
  color: rgba(255, 255, 255, 0.50);
  font-size: 16px;
  font-style: normal;
  font-weight: 510;
  line-height: 22.909px;
}