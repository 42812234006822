.header {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.button-container {
  display: flex;
  margin-bottom: 56px;
}

.button {
  height: 48px;
  margin: 0 auto;
  margin-top: 31px;
  color: white;
  top: 22%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  border-radius: 16px;
  background: rgba(217, 217, 217, 0.05);
  backdrop-filter: blur(7px);
  transition: transform 0.3s ease;
}

.button:active {
  transform: scale(1.1);
  /* Уменьшаем размер кнопки на 5% при активации */
}

.button.hand {
  margin-right: 12px !important;
}

.button.diamond-container {
  align-items: center;
}

.button-icon,
.hand-ok {
  width: 26px;
  margin-right: 10px;
}

.hand-ok {
  width: 26px;
  margin-right: 10px;
}

.diamond {
  width: 22px;
  margin-right: 10px;
}

.plus-white {
  width: 24px;
}

.button-text {
  color: #fff;
  text-align: center;
  font-family: "SF Pro", "SF Pro Display", "Helvetica", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18.9px;
}

.total-div {
  font-size: xxx-large;
  color: white;
  bottom: 0;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.img-coin-home {
  height: 38px;
  margin-right: 7px;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 2s;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 2s;
}

.img-era {
  height: 27px;
}

.header-row {
  display: flex;
  align-items: center;
  margin-right: 12px;
  justify-content: center;
  gap: 8px;
}

.era-text {
  font-size: 13px;
  font-weight: bold;
}

.era-text-trans {
  font-size: 13px;
  opacity: 0.9;
  color: rgba(255, 255, 255, 0.4);
}
