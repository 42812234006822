.loader-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "SF Pro", "SF Pro Display", "Helvetica", sans-serif;
  color: #ffffffe8;
}

.loader-title {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 100px;
}


@keyframes floatAnimation {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px); /* Увеличиваем амплитуду движения */
  }
}

.loader-wrapper img {
  animation: floatAnimation 1.5s ease-in-out infinite; /* Уменьшаем продолжительность анимации для увеличения скорости */
}

