@import url("https://fonts.cdnfonts.com/css/sf-pro-display");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  user-select: none;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
}

.left-align {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.width-100 {
  width: 100%;
}

.justify-end {
  justify-content: end;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

body {
  margin: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  font-family: "SF Pro", "SF Pro Display", "Helvetica", sans-serif;
}

.wrapper-team {
  margin: 0 auto;
  width: 100%;
  max-width: 430px;
  /* min-width: 350px; */
  display: flex;
  height: 100vh;
  position: relative;
  padding: 0 15px;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  background: url(/public/assets/img/league/stars.png),
    linear-gradient(
      180deg,
      #05011e 2.94%,
      #7c01c8 28.76%,
      #500573 68.63%,
      #000423 95.19%
    );
  background-position: top;
}

.top-imgs-team {
  height: 150px;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 23px;
}

.confetti {
  width: 264px;
}

.big-hand {
  width: 140px;
  position: absolute;
  top: 19px;
}

.big-white-title {
  color: #fff;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 31px;
  margin-bottom: 16px;
}

.team__top-button-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0px 60px;
  /* margin-bottom: 52px; */
}

.top-button {
  width: 100%;
  height: 48px;
  margin: 0 auto;
  margin-bottom: 10px;
  color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0px 24px 0px 12px;

  border-radius: 16px;
  background: rgba(217, 217, 217, 0.05);
  backdrop-filter: blur(7px);
  transition: transform 0.3s ease;
}

.top-button:active {
  transform: scale(1.05);
  /* Уменьшаем размер кнопки на 5% при активации */
}

.middle-coin {
  margin-right: 4px;
}

.bonus-coin {
  color: #f4cd27;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18.9px;
}

.two-row {
  display: flex;
  align-items: flex-start;
  gap: 5px;
}

.coin-svg {
  margin-right: 12px;
}

.coin-bonus-container,
.laague-bonus-container {
  display: flex;
  align-items: center;
}

.top-leaders-text {
  color: #fff;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18.9px;
  margin-right: 5px;
}

.middle-title {
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  margin-bottom: 22px;
  text-align: left;
}

/* Froinds list component */

.friends-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  border-radius: 24px;
  background: rgba(217, 217, 217, 0.05);
  padding: 15px;
  padding-bottom: 75px;
  margin-bottom: 32px;
}

.invite-button-team {
  width: 100%;
  height: 69px;
  margin: 0 auto;
  margin-bottom: 10px;
  color: white;
  display: flex;
  align-items: center;
  padding: 8px 12px 8px 12px;

  border-radius: 16px;
  background: rgba(217, 217, 217, 0.05);
  backdrop-filter: blur(7px);
  transition: transform 0.3s ease;
  margin-bottom: 22px;
}

.invite-button-team:active {
  transform: scale(1.03);
}

.total-container {
  display: flex;
  align-items: center;
}

.invite-two-row {
  display: flex;
  align-items: center;
  gap: 5px;
}

.row {
  display: flex;
  align-items: center;
  margin-right: 12px;
}

.avatar {
  margin-right: 12px;
}

.league-mini-icon {
  width: 16px;
  padding-bottom: 1px;
  margin-right: 5px;
}

.invite-row-coin {
  padding-bottom: 1px;
  margin-right: 5px;
}

.team_era-container {
  max-width: 305px;
  height: 48px;
  margin: 0 auto;
  color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 16px;
  background: rgba(217, 217, 217, 0.05);
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
  transition: transform 0.3s ease;
  padding: 8px 8px 8px 16px;
  margin-bottom: 40px;
}

.team__era-content {
  display: flex;
  align-items: center;
  margin-right: 12px;
  justify-content: space-between;
  gap: 8px;
}

.team__era-text {
  font-size: 13px;
  font-weight: bold;
  text-wrap: nowrap;
}

.mrmR-85 {
  margin-right: 85px;
}

.share-link-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 16px;
  border: 0.3px solid #fff;
  background: linear-gradient(
    90deg,
    rgba(204, 0, 255, 0.6) 0%,
    rgba(255, 153, 0, 0.6) 100%
  );
  backdrop-filter: blur(7px);

  padding: 8px 12px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  margin-bottom: 8px;
}

.share-text-meddium {
  font-size: 16px;
  font-style: normal;
  font-weight: 590;
  line-height: 18.9px;
}

.share-text-small {
  font-size: 12px;
  font-style: normal;
  font-weight: 510;
  line-height: 18.9px; /* 157.5% */
}

.addBgTrans {
  border-radius: 16px;
  background: rgba(217, 217, 217, 0.05);
  /* backdrop-filter: blur(7px); */

  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.addBgTrans:active {
  transform: scale(1.01);
}

.darkContainer {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  justify-content: space-between;
  border-radius: 16px;
  background: rgba(217, 217, 217, 0.05);
  backdrop-filter: blur(7px);

  font-size: 16px;
  font-style: normal;
  font-weight: 590;
  line-height: 18.9px;
}

.darkContainer svg {
  margin-right: 12px;
}

.mRb-8 {
  margin-bottom: 8px;
}

.mRb-32 {
  margin-bottom: 32px;
}

.mini-butttom-icons {
  width: 50px;
}

.invite-info-text {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 4px 0px;
}

.one-time-bonus {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 590;
  line-height: 18.9px;
}

.small-golden-text {
  color: #f4cd27;
  font-size: 12px;
  font-style: normal;
  font-weight: 274;
  line-height: 18.9px;
}

.small-text-button {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 274;
  line-height: 18.9px;
}

.invite-bonus-coin {
  color: #f4cd27;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  margin-right: 12px;
}

.more-details-button {
  color: #fff;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18.9px;

  display: flex;
  align-items: center;
  transition: transform 0.3s ease;
  margin-right: 3px;
}

.more-details-button:active {
  transform: scale(1.1);
}

.more-details-button span {
  margin-right: 3px;
}

.button-big-yellow-team {
  color: #232529;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;

  /* width: calc(100% - 30px); */
  width: 100%;
  height: 48px;
  padding: 16px 24px;
  border-radius: 16px;
  background: linear-gradient(180deg, #ffc700 0%, #d78100 100%);
  margin-bottom: 40px;
  transition: transform 0.3s ease;

  /* position: fixed;
  bottom: -35px;
  z-index: 100;
  max-width: 383px; */
}

.button-big-yellow-team:active {
  transform: scale(1.01);
}

@media screen and (min-width: 431px) {
  /* CSS стили, применяемые при ширине экрана больше 430px */
  .wrapper {
    overflow-x: visible;
  }
}
